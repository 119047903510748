<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"> </i>
          <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="handleEnable(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="handleEnable(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          @selection-change="handleSelectionChange"
          highlight-current-row
          ref="elTable"
          row-key="consumeDetailId"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="80"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <addLog
      :addVisible.sync="addVisible"
      @close="closeDialog"
      @refresh="queryList"
      :editType="editType"
      :clickId="clickId"
    ></addLog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import addLog from "./addLog.vue";
import {
  getReleaseRecordList,
  batchUpRelease,
  batchDownRelease,
  exportReleaseRecordList,
} from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
export default {
  name: "releaseLogList",
  components: { addLog },
  data() {
    return {
      total: 0,
      loading: false,
      searchForm: {
        platformTypeList: [1, 3],
        pageIndex: 1,
        pageSize: 20,
      },
      columnData: [
        {
          fieldName: "releaseStatusName",
          display: true,
          fieldLabel: "发布状态",
          width: 100,
          disabled: false,
          fixed: true,
          align: "center",
        },
        {
          fieldName: "projectName",
          display: true,
          fieldLabel: "项目名称",
          width: 140,
          disabled: false,
          fixed: true,
          align: "center",
        },
        {
          fieldName: "projectBranch",
          display: true,
          fieldLabel: "项目分支",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "projectVersion",
          display: true,
          fieldLabel: "版本",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "expectReleaseTime",
          display: true,
          fieldLabel: "期望发布时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "releaseUser",
          display: true,
          fieldLabel: "发布人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "dbChange",
          display: true,
          fieldLabel: "DB变更",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },

        {
          fieldName: "codeChange",
          display: true,
          fieldLabel: "修改代码列表",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "testUser",
          display: true,
          fieldLabel: "测试人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "testItem",
          display: true,
          fieldLabel: "测试项",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "testConclusion",
          display: true,
          fieldLabel: "测试结论",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },

        {
          fieldName: "menuUrl",
          display: true,
          fieldLabel: "菜单系统配置路径",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "reviewerUser",
          display: true,
          fieldLabel: "Reviewer",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "reviewConclusion",
          display: true,
          fieldLabel: "Review结果",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "productUser",
          display: true,
          fieldLabel: "产品",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "developUser",
          display: true,
          fieldLabel: "研发",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "allTestUser",
          display: true,
          fieldLabel: "测试",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "functionDescription",
          display: true,
          fieldLabel: "版本描述",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
      ], //表头
      tableData: [],
      refTable: "elTable",
      queryListAPI: getReleaseRecordList,
      editType: "",
      clickId: "",
      addVisible: false,
      selColumnId: [],
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map((item) => {
        return item.releaseRecordId;
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1,
        },
        {
          label: "编辑",
          id: 2,
        },
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      this.addVisible = true;
      if (val.id == 1) {
        this.editType = 1;
      }
      if (val.id == 2) {
        this.editType = 2;
      }
      this.clickId = row.releaseRecordId;
    },
    handleAdd() {
      this.addVisible = true;
      this.editType = 0;
    },
    closeDialog(prop) {
      this[prop] = false;
    },
    //点击启用、禁用
    handleEnable(type) {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        let confirmTitleKeyWord = type == 1 ? "启用" : "禁用";
        this.$confirm(
          `是否确认${confirmTitleKeyWord}所选配置?`,
          `${confirmTitleKeyWord}配置`,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            if (type == 1) {
              this.enableProp();
            } else {
              this.disenableProp();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: `已取消${confirmTitleKeyWord}`,
            });
          });
      }
    },
    async enableProp() {
      try {
        this.loading = true;
        let res = await batchUpRelease(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    async disenableProp() {
      try {
        this.loading = true;
        let res = await batchDownRelease(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //  导出
    exportData() {
      exportData(this, exportReleaseRecordList);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
